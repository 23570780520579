/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        $('.slicktest').slick({
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          arrows:true,
          centerMode: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: true,
                slidesToShow: 1
              }
            }
          ]
        });

        setTimeout(function(){ 
          $(".slick-next").click();
          }, 500
        );

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'nosotros': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.slick_nosotros').slick({
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          arrows:true,
          centerMode: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: true,
                slidesToShow: 1
              }
            }
          ]
        });

        setTimeout(function(){ 
          $(".slick-next").click();
          }, 500
        );

        setTimeout(function(){ 
          $(".slick-next").click();
          }, 1500
        );

      }
    },
    // Home page
    'liderazgo_educativo': {
      init: function() {

        $('.slick_liderazgo').slick({
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          arrows:true,
          centerMode: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: true,
                slidesToShow: 1
              }
            }
          ]
        });

        setTimeout(function(){ 
          $(".slick-next").click();
          }, 500
        );

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Postula
    'postula': {
      init: function() {
        // JavaScript to be fired on all pages
        
        

      },
      finalize: function() {        
      }
    },

    // Donaciones
    'donaciones': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.multiple-items').slick({
          infinite: true,
          slidesToShow: 6,
          slidesToScroll: 6,
          autoplay: true,
          autoplaySpeed: 3000,
          pauseOnHover: false,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplaySpeed: 2000,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplaySpeed: 1500,
              }
            }
          ]

        });

      },
      finalize: function() {        
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
